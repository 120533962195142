import * as React from "react"
import { useState } from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Text, SimpleGrid, Button } from "@chakra-ui/react"
import { Container } from "../../components/Container"
import { Heading } from "../../components/Heading"
import { Layout } from "../../layouts/default"
import { client } from "../../lib/sanity"
import PortableText from "../../components/PortableText"
import langString from "../../locales/lang.json"

// Sanity helpers
import { FormulationChallengeBlock } from "../../components/Blocks/FormulationChallengeBlock"
import { Helmet } from "react-helmet"
import { DynamicLink } from "../../components/DynamicLink"
import langKeys from "../../locales/lang.json"
import { useLocale } from "../../context/locale/LocaleProvider"

// Limit for pagination
let limit = 9

const ScientificPapers = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  const { edges } = data.allSanityPaper
  const [pageCount, setPageCount] = useState(1)
  const [paginatedItems, setPaginatedItems] = useState([])
  const [hideButton, setHideButton] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [filterLoading, setFilterLoading] = useState(false)

  // Query for pagination
  const groq = `*[_type == 'paper'] | order(publishedAt desc) | [$from...$to]{ id, title, subtitle, abstract, cardText, slug, featureImage { asset } }`

  // Get the data
  const getData = async ({ query, params }) => {
    try {
      return await client.fetch(query, params)
    } catch (err) {
      console.error("Unable to retrieve preview data", err)
    }
  }

  const callSanity = async () => {
    setLoading(true)

    const res = await getData({
      query: groq,
      params: {
        from: pageCount * limit, // start from 3, 6, 9
        to: pageCount * limit + limit,
      },
    })

    if (res && res.length > 0) {
      console.log("res", res)
      setPaginatedItems([...paginatedItems, ...res])
    } else {
      console.warn("No res", res)
      setHideButton(true)
    }

    setLoading(false)
  }

  // Call the page up method
  const pageUp = () => {
    callSanity()
    setPageCount(pageCount + 1)
  }

  // Perform a call up front, if no results come back then do nothing
  React.useEffect(() => {
    const checkData = async () => {
      try {
        const res = await getData({
          query: groq,
          params: {
            from: pageCount * limit, // start from 3, 6, 9
            to: pageCount * limit + limit,
          },
        })

        if (res.length === 0) {
          setHideButton(true)
        }
      } catch (err) {
        console.error("Unable to call sanity", err)
        setHideButton(true)
      }
    }

    checkData()
  }, [])

  return (
    <Layout locale={locale}>
      <Helmet>
        <meta
          name="title"
          content="Scientific papers on membrane emulsification"
        />
        <meta
          name="description"
          content="A useful library of academic papers covering  the field of membrane emulsification and encapsulation techniques"
        />
      </Helmet>

      <Box height="500px" position="relative" mb={-24} bg="blue.100">
        <StaticImage
          src="../../images/scientific-papers-header.jpg"
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>

      <Container px={0}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            p={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={2}>
              {langString.papers.title[stateLocale]}
            </Heading>
            <Text>{langString.papers.subtitle[stateLocale]}</Text>
          </Box>
        </Flex>

        <Container bg="white" mb={20}>
          <SimpleGrid columns={1} gap={16}>
            {edges.length > 0 &&
              edges.map(paper => {
                return (
                  <Box
                    pb={2}
                    shadow="lg"
                    width="100%"
                    borderWidth="1px"
                    overflow="hidden"
                    display="flex"
                    flexDir="column"
                  >
                    <DynamicLink
                      to={`/useful-resources/scientific-papers/${paper.node.slug.current}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Box p="4">
                        <Heading
                          as="h3"
                          fontSize={32}
                          bg="white"
                          padding="8px 16px 10px"
                          mb={4}
                        >
                          {paper.node.title[locale] || paper.node.title.en}
                        </Heading>

                        {paper.node._rawAuthors && (
                          <Box mb={5} px="16px">
                            <PortableText
                              blocks={
                                paper.node._rawAuthors[locale] ||
                                paper.node._rawAuthors.en
                              }
                            />
                          </Box>
                        )}

                        {paper.node._rawAbstract && (
                          <Box mb={5} px="16px">
                            <PortableText
                              blocks={
                                paper.node._rawAbstract[locale] ||
                                paper.node._rawAbstract.en
                              }
                            />
                          </Box>
                        )}

                        <Box px={4}>
                          <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                            {langKeys["read-more"][stateLocale]}
                          </Button>
                        </Box>
                      </Box>
                    </DynamicLink>
                  </Box>
                )
              })}

            {paginatedItems.length > 0 &&
              paginatedItems.map(paper => {
                return (
                  <Box
                    pb={2}
                    shadow="lg"
                    width="100%"
                    borderWidth="1px"
                    overflow="hidden"
                    display="flex"
                    flexDir="column"
                  >
                    <Link
                      to={`/useful-resources/scientific-papers/${paper.slug.current}`}
                      style={{ textDecoration: "none " }}
                    >
                      <Box p="4">
                        <Heading
                          as="h3"
                          fontSize={32}
                          bg="white"
                          padding="8px 16px 10px"
                          mb={4}
                        >
                          {paper.title[stateLocale]}
                        </Heading>

                        {paper.abstract && (
                          <Box px={4}>
                            <PortableText
                              blocks={
                                paper.abstract[locale] || paper.abstract.en
                              }
                            />
                          </Box>
                        )}

                        <Box px={4}>
                          <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                            {langKeys["read-more"][stateLocale]}
                          </Button>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                )
              })}
          </SimpleGrid>

          {!hideButton && (
            <Flex justifyContent="center" mt={16}>
              <Button
                onClick={pageUp}
                isLoading={loading}
                loadingText="Please wait..."
              >
                {langString.papers["load-more"][stateLocale]}
              </Button>
            </Flex>
          )}
        </Container>

        <Container
          bg="white"
          pt={4}
          borderTopWidth={2}
          borderTopColor="gray.300"
        >
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PapersQuery {
    allSanityPaper(limit: 9, sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          cardText {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          _rawAuthors
          _rawAbstract
          slug {
            current
          }
        }
      }
    }
  }
`

export default ScientificPapers
